define([
    'lodash',
    'mobx',
    'dataFixer',
    'coreUtils',
    'utils/page/pageUtils',
    'utils/seo/seoUtils',
    'utils/core/data/mobxDataHandlers',
    'coreMultilingual',
    'experiment'
], function (_, mobx, dataFixer, coreUtils, pageUtils, seoUtils, mobxDataHandlers, coreMultilingual, experiment) {
    'use strict';

    const observableStores = [
        'pageStubComponents',
        'renderFlags',
        'platform',
        'activeModes',
        'layoutAdjustment',
        'resolvedDataMaps',
        'svgShapes',
        'wixappsRenderCounters',
        '_currentPageIds',
        '_currentRootInfos',
        'mediaQualityStore',
        'requestModel',
        'customUrlMapping',
        'rendererModel',
        'screenSize',
        'mobileStructures',
        'platformWidgetsState',
        'prefetchPages',
        'currentUrl',
        'multilingual',
        'nativeComponents'
    ];

    const computedFunctions = [
        'getPagesDataItems',
        'getMainPageId',
        'getCurrentPopupId',
        'getAllStylesFromPossiblyRenderedRoots',
        'getGeneralTheme',
        'getFontsMap',
        'getColorsMap',
        'getAllPageIds',
        'isMobileView'
    ];

    const observeOnInit = ['isMobileView'];

    (function () {
        const origIsArray = _.isArray;
        _.isArray = function (possibleArray) {
            return mobx.isObservableArray(possibleArray) || origIsArray(possibleArray);
        };
    }());

    function addTagIfMissing(tags, newTag) {
        if (!newTag.content) {
            return;
        }

        //find tag in array "tags" with the same main attribute like "name" or "property"
        const mainAttr = _(newTag).pick(['name', 'property']).keys().head();
        const sameMainAttr = {};

        sameMainAttr[mainAttr] = newTag[mainAttr];

        if (!_.find(tags, sameMainAttr)) {
            tags.push(newTag);
        }
    }

    function initObservableStores(siteData, displayedJsonDal) {
        if (Object.prototype.watch) {
            // Firefox has watch property on the prototype that breaks mobx map of objects with 'watch' property.
            // Deleting the watch property resolves it.
            Object.prototype.watch = undefined; //eslint-disable-line no-extend-native
        }

        _.forEach(observableStores, function (storeName) {
            displayedJsonDal.setByPath([storeName], siteData[storeName]);
        });
    }

    function initObservablePrimitives(siteData, displayedJsonDal) {
        _(siteData)
            .pickBy(function (value) {
                return _.isNumber(value) ||
                    _.isBoolean(value) ||
                    _.isString(value) ||
                    _.isUndefined(value) ||
                    _.isNull(value);
            })
            .forOwn(function (value, key) {
                displayedJsonDal.setByPath([key], value);
            });
    }

    function initComputedFunctions(siteData) {
        _.forEach(computedFunctions, _.bind(function (funcName) {
            const origFunction = siteData[funcName];
            const computedFuncName = `SiteData.${funcName}`;
            const computedValue = mobx.computed(origFunction, {context: this, name: `siteData.${computedFuncName}`});

            function getComputed() {
                return computedValue.get();
            }

            siteData[funcName] = getComputed;

            if (_.includes(observeOnInit, funcName)) { // Add an observer on the computed function in order to save recalculations during render stage.
                mobx.observe(computedValue, _.noop);
            }
        }));
    }

    function resolveCurrentLanguage(siteModel, translationLangs = []) {
        const lang = siteModel.currentUrl && siteModel.currentUrl.query.lang;
        if (lang && _.find(translationLangs, {languageCode: lang})) {
            return lang;
        }
        return _.get(siteModel.rendererModel, 'sitePropertiesInfo.multilingualInfo.originalLanguage.languageCode', '');
    }

    function isWixappsBlogTagPage(clientSpecMap, rootNavigationInfo, pageData) {
        return _.get(clientSpecMap, [pageData.appInnerID, 'packageName']) === 'blog' &&
            /^(featured\/)?tag\//i.test(rootNavigationInfo.pageAdditionalData);
    }

    function FullSiteData(siteModel) { // eslint-disable-line complexity
        if (!siteModel) {
            return;
        }

        if (siteModel) {
            if (siteModel.wixData) {
                siteModel.wixBiSession.suspectDeadCode('deprecatedSiteModelMigrater');
                dataFixer.deprecatedSiteModelMigrater(siteModel);
            }
        }

        coreUtils.mobxDataHandlers.setMobxDataHandlers(this, mobxDataHandlers);
        const displayedJsonDal = coreUtils.DALFactory.getInstance(this, {pagesData: siteModel.pagesData || {}});

        coreUtils.SiteData.call(this, siteModel, displayedJsonDal.getByPath.bind(displayedJsonDal));

        const mlInfo = siteModel.rendererModel.sitePropertiesInfo && siteModel.rendererModel.sitePropertiesInfo.multilingualInfo;
        if (mlInfo && mlInfo.originalLanguage) {
            mlInfo.originalLanguage.name = coreMultilingual.mapLanguageCodeToName(mlInfo.originalLanguage.languageCode);
            mlInfo.translationLanguages = mlInfo.translationLanguages.map(lang =>
                Object.assign({}, lang, {name: coreMultilingual.mapLanguageCodeToName(lang.languageCode)})
            );
            if (this.renderFlags.componentViewMode !== 'editor') {
                const currentLanguageCode = resolveCurrentLanguage(siteModel, mlInfo.translationLanguages);
                this.multilingual.currentLanguageCode = currentLanguageCode;
            }
        }

        initObservableStores(this, displayedJsonDal);
        initObservablePrimitives(this, displayedJsonDal);
        initComputedFunctions(this);

        mobx.extendObservable(this, {
            observableUpdateIndex: 0,
            siteAspectsData: mobx.observable.map(),
            relayoutBlockedByQueue: false,
            renderingCompsMap: mobx.observable.map({}, 'renderingCompsMap'),
            renderRealtimeConfig: {
                previewTooltipCallback: null,
                hideTextComponent: null,
                compsToHide: {
                    [coreUtils.constants.VIEW_MODES.DESKTOP]: mobx.observable.shallowMap({}, 'compsToHideDesktop'),
                    [coreUtils.constants.VIEW_MODES.MOBILE]: mobx.observable.shallowMap({}, 'compsToHideMobile')
                },
                compsToShowOnTop: null,
                compsToShowWithOpacity: null
            }
        });

        this.dynamicPages.headData = this.dynamicPages.headData || {};
        this.dynamicPages.data = this.dynamicPages.data || {};
        this.dynamicPages.currMetaTags = {};

        this.pageIdToOgTags = {};
        this.pageIdToTwitterTags = {};

        _.bindAll(this, ['getDynamicPageTitle', 'getCurrentUrlPageTitle', 'getCurrDynamicPageMetaTags',
            'resetCurrDynamicPageMetaTags', 'getDynamicPageSEOMetaData', 'addDynamicPageData', 'getDynamicPageData',
            'addDynamicPageHeadData', 'getDynamicPageHeadData', 'getPageOgTags', 'setPageOgTags', 'getPageSEOMetaData',
            'getPageTwitterTags', 'setPageTwitterTags', 'getCurrentPageSEOMetaData', 'getTpaSEOMetaData']);
    }

    FullSiteData.prototype = _.create(coreUtils.SiteData.prototype, {
        constructor: FullSiteData,

        getCurrentPageTitleBase(withAddedSiteName = true) { // eslint-disable-line complexity
            const siteTitleSEO = this.rendererModel.siteInfo.siteTitleSEO || '';
            let title = siteTitleSEO;
            if (this.publicModel && this.publicModel.siteDisplayName) {
                title = this.publicModel.siteDisplayName;
            }
            const urlPageId = this.getCurrentUrlPageId();
            const pageData = this.getDataByQuery(urlPageId);

            const pageName = pageData.title || '';
            const pageTitleSEO = pageUtils.extractPageTitleFromOriginalTitle(pageData.pageTitleSEO, withAddedSiteName, siteTitleSEO);
            const isHomePage = this.isHomePage(urlPageId);
            const dynamicTitle = this.getDynamicPageTitle(urlPageId, withAddedSiteName);

            if (dynamicTitle) {
                title = dynamicTitle;
            } else if (pageTitleSEO) {
                title = pageTitleSEO;
            } else if (!isHomePage) {
                title = withAddedSiteName ? `${title} | ${pageName}` : pageName;
            }

            return title;
        },

        getDynamicPageTitle(pageId, withAddedSiteName = true) {
            if (!this.isDynamicPage(pageId)) {
                return;
            }

            const dynamicPageTitle = _.get(this.dynamicPages.headData, [pageId, 'title']);
            if (!dynamicPageTitle) {
                const dynamicPagePrefix = _.get(this.dynamicPages.data, [pageId, 'routerDefinition', 'prefix']);
                const innerRoute = this.getRootNavigationInfo().innerRoute;
                let siteTitle = this.rendererModel.siteInfo.siteTitleSEO || '';
                if (this.publicModel && this.publicModel.siteDisplayName) {
                    siteTitle = this.publicModel.siteDisplayName;
                }

                const pageName = `${dynamicPagePrefix} ${innerRoute}`;
                const defaultTitle = withAddedSiteName ? `${siteTitle} | ${pageName}` : pageName;
                return defaultTitle.trim();
            }

            return seoUtils.sanitizeHTMLInjectionFromString(dynamicPageTitle);
        },

        getCurrentUrlPageName() {
            return this.getCurrentPageTitleBase(false);
        },

        getCurrentUrlPageTitle() {
            return this.getCurrentPageTitleBase(true);
        },

        getCurrDynamicPageMetaTags() {
            return this.dynamicPages && this.dynamicPages.currMetaTags;
        },

        resetCurrDynamicPageMetaTags() {
            if (this.dynamicPages) {
                this.dynamicPages.currMetaTags = {};
            }
        },

        getDynamicPageSEOMetaData(pageId) {
            const userHeadData = this.getDynamicPageHeadData(pageId);
            const pageSEOMetaTags = seoUtils.getSEOMetaTagsForDynamicPage(userHeadData, this.getCurrentUrl(), this.getCurrentUrlPageTitle(), this.serviceTopology);
            this.dynamicPages.currMetaTags = pageSEOMetaTags;
            return pageSEOMetaTags;
        },

        addDynamicPageData(pageId, dynamicPageData, routerDefinition) {
            _.set(this.dynamicPages.data, [pageId], {
                routerData: dynamicPageData,
                routerDefinition
            });
        },

        getDynamicPageData(pageId) {
            if (this.dynamicPages && this.dynamicPages.data && this.isDynamicPage(pageId)) {
                return this.dynamicPages.data[pageId || this.getPrimaryPageId()];
            }
            return null;
        },

        addDynamicPageHeadData(pageId, dynamicPageHeadData) {
            _.set(this.dynamicPages.headData, [pageId], dynamicPageHeadData);
        },

        getDynamicPageHeadData(pageId) {
            if (this.dynamicPages && this.dynamicPages.headData) {
                return this.dynamicPages.headData[pageId || this.getPrimaryPageId()];
            }
            return null;
        },

        getPageOgTags(pageId, pageData) {
            if (_.isUndefined(this.pageIdToOgTags[pageId])) {
                this.pageIdToOgTags[pageId] = [];
            }

            const ogTags = _.cloneDeep(this.pageIdToOgTags[pageId]);

            addTagIfMissing(ogTags, {property: 'og:title', content: this.getCurrentUrlPageTitle()});
            addTagIfMissing(ogTags, {property: 'og:url', content: this.getCurrentUrl()});
            addTagIfMissing(ogTags, {property: 'og:description', content: _.get(pageData, 'descriptionSEO')});

            return ogTags;
        },


        setPageOgTags(pageId, ogTags) {
            this.pageIdToOgTags[pageId] = ogTags;
        },

        getPageTwitterTags(pageId) {
            const twitterTags = _.cloneDeep(this.pageIdToTwitterTags[pageId] || []);

            return twitterTags;
        },

        setPageTwitterTags(pageId, twitterTags) {
            this.pageIdToTwitterTags[pageId] = twitterTags;
        },

        getPageSEOMetaData(pageId) {
            const pageSEOMetaTags = {};
            const pageData = this.getDataByQuery(pageId);

            if (pageData) {
                pageSEOMetaTags.description = pageData.descriptionSEO;
                pageSEOMetaTags.keywords = pageData.metaKeywordsSEO;

                pageSEOMetaTags.robotIndex = 'index';
                if (pageData.indexable === false || isWixappsBlogTagPage(this.getClientSpecMap(), this.getRootNavigationInfo(), pageData)) {
                    pageSEOMetaTags.robotIndex = 'noindex';
                }
                pageSEOMetaTags.ogTags = this.getPageOgTags(pageId, pageData);

                if (experiment.isOpen('sv_twitterMetaTags', {rendererModel: this.rendererModel})) {
                    pageSEOMetaTags.twitterTags = this.getPageTwitterTags(pageId, pageData);
                }
            }

            return pageSEOMetaTags;
        },

        getPageSEOLinkHref(pageId, linkRel) {
            const pageData = this.getDataByQuery(pageId);
            return _.get(pageData, linkRel);
        },

        getCurrentPageSEOMetaData(pageId) {
            const currDynamicPageMetaTags = this.getDynamicPageSEOMetaData(pageId);
            return !_.isEmpty(currDynamicPageMetaTags) ? currDynamicPageMetaTags : this.getPageSEOMetaData(pageId);
        },

        getTpaSEOMetaData() {
            const tpaMetaDataItems = _.chain(this)
                .get('ssr.seoTpaModel.applicationsSeoData')
                .map('compData.meta')
                .compact()
                .map(tags => _.pickBy(tags, _.negate(_.isNil)))
                .value();
            return _.assign({}, ...tpaMetaDataItems);
        }
    });

    return FullSiteData;
});
